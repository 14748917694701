import { Divider } from "../divider";
import { PageSubsection } from "../page_sections";
import Typography from "../typography";
import { JsonContent } from "../static_content";
import polioJson from "../../content/polio.json";
import { VaccinationStatus } from "../vaccination_progress";
import { formatDate } from "../../utils/common";
import { InfoPanel } from "../info_panel";

type PolioContentProps = {
    status: VaccinationStatus
}

export const nextDoseDue = ({ next_dose_due }: VaccinationStatus) => {

    if (next_dose_due){

        const dateRegex = new RegExp(/\d{4}-\d{2}-\d{2}/);
        const match = dateRegex.test(next_dose_due);

        if (match){
            const dateStr = formatDate(new Date(next_dose_due))
            return (
                <Typography variant="listTitle">Next dose due from: {dateStr}</Typography>
            )
        } else {
            return (
                <Typography variant="listTitle">Next dose due: {next_dose_due}</Typography>
            )
        }
    }
}

export const OPVPanel = ({ has_opv, dual_ipv_opv }: VaccinationStatus) => {
    if (has_opv && !dual_ipv_opv) {
        return (
            <PageSubsection>
                <InfoPanel
                    severity="warning"
                    title="OPV"
                >
                    <Typography variant="bodyTextLarge">
                        Records contain one or more doses of oral polio vaccine. Patients taking OPV are recommended to take at least one dose of IPV for comprehensive protection.
                    </Typography>
                </InfoPanel>
            </PageSubsection>
        );
    }

    return null
};

export const DualIPVOPVPanel = ({ dual_ipv_opv }: VaccinationStatus) => {
    if (dual_ipv_opv) {
        return (
            <PageSubsection>
                <InfoPanel
                    severity="warning"
                    title="IPV and OPV taken together"
                >
                    <Typography variant="bodyTextLarge">
                        A minimum of 4 doses is recommended for full vaccination during childhood.
                    </Typography>
                </InfoPanel>
            </PageSubsection>
        );
    }

    return null
};

export const CatchUpPanel = ({ catch_up, action_status }: VaccinationStatus) => {
    if (catch_up && action_status !== 'UNAVAILABLE') {
        return (
            <PageSubsection>
                <InfoPanel
                    severity="warning"
                    title="Catch Up Schedule"
                >
                    <Typography variant="bodyTextLarge">
                        You are following a catch up schedule which may change the total number of recommended doses and/or timing of recommended doses.
                    </Typography>
                </InfoPanel>
            </PageSubsection>
        );
    }

    return null
};

export const PolioContent = ({ status }: PolioContentProps) => (<>
    <PageSubsection>
        <Typography variant="bodyTextMediumDark">
            {status.status_text} [<a target="_blank" rel="noreferrer" href="https://imunis.com/vaccinaton_steps_key.pdf">key</a>]
        </Typography>
    </PageSubsection>

    <PageSubsection>
        {nextDoseDue({...status})}
    </PageSubsection>

    <OPVPanel {...status} />

    <DualIPVOPVPanel {...status} />

    <CatchUpPanel {...status} />

    <Divider />

    <JsonContent>
        {polioJson}
    </JsonContent>
</>)

import { makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, TextButton } from "../../../components/button"
import { BackButton } from "../../../components/buttons/back_btn"
import { BaseContainer } from "../../../components/container"
import { DtapSteps } from "../../../components/context/dtapReducer"
import { DtapContext, useDtapContext } from "../../../components/context/dtapState"
import { DatePicker, toISOString } from "../../../components/date_picker"
import { HeaderTitle } from "../../../components/page_sections"
import Typography from "../../../components/typography"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import { isLoggedIn, getUserFullName, getEmail, getUUID } from "../../../utils/auth";
import { Step as PaperRecordStep } from "../../records/paper/new"

const useStyles = makeStyles((theme) => ({
    textButton: {
        marginTop: theme.spacing(2),
    }
}))

export const AskLater = () => {
    const classes = useStyles()
    const history = useHistory()

    const { state, dispatch } = useDtapContext(DtapContext)

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={() => history.push('/home')}>
                    Close
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton/>

            <HeaderTitle>
                Important - Possible Incorrect Information
            </HeaderTitle>

            <p>
                It’s possible that the current status and number of doses recommended for any of diphtheria, tetanus, pertussis (whooping cough) or polio may be incorrect, because your records appear to be incomplete.
            </p>
            <p>
                It’s important to either upload your hard copy records or self-certify in order to generate an accurate status and dose recommendation.
            </p>

        </BaseContainer>
    )
}

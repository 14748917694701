import { makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, TextButton } from "../../../components/button"
import { BackButton } from "../../../components/buttons/back_btn"
import { BaseContainer } from "../../../components/container"
import { DtapSteps } from "../../../components/context/dtapReducer"
import { DtapContext, useDtapContext } from "../../../components/context/dtapState"
import { DatePicker, toISOString } from "../../../components/date_picker"
import { HeaderTitle } from "../../../components/page_sections"
import Typography from "../../../components/typography"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import { isLoggedIn, getUserFullName, getEmail, getUUID } from "../../../utils/auth";
import { Step as PaperRecordStep } from "../../records/paper/new"
import ErrorDialog from "../../../components/dialogs/error_dialog"

const useStyles = makeStyles((theme) => ({
    textButton: {
        marginTop: theme.spacing(2),
    },
    fullWidth: {
        width: "100%"
    }
}))

export const RecordType = () => {
    const classes = useStyles()
    const history = useHistory()

    const { state, dispatch } = useDtapContext(DtapContext)

    return (
        <BaseContainer noHeader>
            <BackButton/>

            <HeaderTitle>
                Diphtheria, Tetanus, Pertussis & Polio Records
            </HeaderTitle>

            <p>
                If you have a copy of your vaccination records, you can submit them for digitisation & they will be added to your Imunis account:
            </p>

            <Button
                primary
                className={classes.fullWidth}
                onClick={() => {
                    history.push(`/paper-records/new/${PaperRecordStep.ScanFromDtap}`, {
                        subject: {
                            id: getUUID(),
                            fullName: getUserFullName()
                        }
                    })
                }}>
                Submit Vaccination Records
            </Button>

            <p>
                If you don’t have access to your childhood vaccination records but believe you may qualify as fully-vaccinated in childhood,
                self-certification allows you to better manage your vaccination status. This is only available to adults.
            </p>

            <Button
                primary
                className={classes.fullWidth}
                onClick={() => {
                    history.push(`/add-dtap/${getUUID()}/record_details`)
                }}>
                Begin Self-Certification
            </Button>


        </BaseContainer>
    )
}

import { useState, useEffect, ReactNode } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { axiosAuthedInstance } from "../../utils/axiosApi";
import ActionDialog from "../../components/dialogs/action_dialog";
import { BaseContainer } from "../../components/container";
import ErrorDialog from "../../components/dialogs/error_dialog";
import Loading from "../../components/loading";
import { BackButton } from "../../components/buttons/back_btn";
import VaccineCertificate from "./vaccine_certificate";
import Typography from "../../components/typography";
import { StickyFooterGroup } from "../../components/sticky_footer_group";
import { Button, IconButton } from "../../components/button";
import { InfoBox } from "../../components/info_box";
import { TDateISODate, formatDate, pluralString } from "../../utils/common";
import { CircleFlag } from "../../components/flag";
import QRCodeIcon from "../../assets/icons/qr_icon";
import TrashIcon from "../../assets/icons/trash";
import { MiniTooltipDrawer } from "../../components/tooltip_drawer";
import { DrawerContent } from "../../components/drawer_content";
import PatientRecordsIcon from "../../assets/icons/patient_records";
import { Divider } from "../../components/divider";
import { InfoPanel } from "../../components/info_panel";
import theme from "../../styles/theme";
import { PageDescription, PageSection } from "../../components/page_sections";
import ListItem from "../../components/lists/list_item";
import { isCancel } from "axios";
import { VaccineEvent, VaccineRecord, vaccineEventName } from "../records/utils";
import ImunisIssuerIcon from "../../assets/icons/imunis_issuer";


const useResultItemStyles = makeStyles((theme) => ({
    records: {
        margin: theme.spacing(2, 0),
        wordWrap: 'break-word'
    },
    fieldTitle: {
        marginBottom: theme.spacing(0.25)
    },
    extraText: {
        marginTop: theme.spacing(0.25),
    },
}))

const ResultItem = ({title, value, extra}: {
    title: ReactNode
    value: ReactNode
    extra?: ReactNode
}) => {
    const classes = useResultItemStyles()
    return (
        <div className={classes.records}>
            <Typography variant="bodyTextSmall" className={classes.fieldTitle}>
                {title}
            </Typography>
            <Typography variant="bodyTextLargeBold">
                {value || '-'}
            </Typography>
            {extra &&
                <Typography variant="bodyTextSmallDark" className={classes.extraText}>
                    {extra}
                </Typography>
            }
        </div>
    )
}


const useStyles = makeStyles((theme) => ({
    infoBox: {
        backgroundColor: theme.palette.grey[100], // #f5f5f5
        margin: theme.spacing(2, -3),
    },
    vaccineTitle: {
        display: 'flex',
        gap: theme.spacing(1),
    },
    vaccineSubtitle: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        margin: theme.spacing(1.5, 0),
    },
    vaccineSubtitleIcon: {
        fontSize: '24px',
    },
    titleImages: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.grey[600],
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    deleteIconContainer: {
        marginRight: theme.spacing(-2),
    },
    icon: {
        fontSize: '24px',
    },
    qrWrapper: {
        marginTop: theme.spacing(5)
    },
}))

type MergedVaccineRecord = VaccineRecord & VaccineEvent & { id: keyof VaccineRecord}

type QRCertificate = {
    vaccines: {
        vaccine_date: TDateISODate
    }[]
}

const VaccineRecord = ({relationship, onClose, ...props}: {
    relationship: 'NA' | 'PG' | 'SG'
    onClose: (options: unknown) => void
    vaccine?: MergedVaccineRecord
}) => {
    const location = useLocation<{ vaccine?: MergedVaccineRecord }>()
    const [vaccine, _] = useState<MergedVaccineRecord>((props.vaccine || location.state.vaccine) as MergedVaccineRecord)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string|null>(null)
    const [errorOpen, setErrorOpen] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [certificateRecord, setCertificateRecord] = useState<QRCertificate|null>(null)
    const [qrData, setQrData] = useState<string|null>(null)

    const classes = useStyles();
    const history = useHistory();

    // eslint-disable-next-line @typescript-eslint/unbound-method
    onClose = onClose || history.goBack

    const isPaperRecord = vaccine.type === "PAPER"
    const isPatientCertified = vaccine.type === "PATIENT"
    const isQrRecord = vaccine.type === "QR"
    const isAccountHolder = relationship === 'NA'
    const canDelete = relationship !== 'SG' && (isQrRecord || isPatientCertified)
    const provider = vaccine.provider === 'Unknown' ? null : vaccine.provider
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const isDtapSelfCertification = vaccine.sub_type === 'DTAP'
    const multipleRecs = (certificateRecord && certificateRecord.vaccines.length > 1) || isDtapSelfCertification
    const isHKCertificate = isQrRecord && vaccine.country === 'HK'

    function deleteRecord() {
        setLoading(true)
        axiosAuthedInstance.delete(`/vaccinations/user/vaccine-record/${vaccine.id}/`).then(() => {
            if(isDtapSelfCertification){
                // There can only ever be one instance of DTaP self cert
                // Re-enabled the frontend option to add a self cert
                localStorage.setItem('missing_dtap', "true")
            }
            setLoading(false)
            onClose({reload: true})
        }).catch(err => {
            if (isCancel(err)) return

            console.error(err)
            setLoading(false)
            let msg = 'Failed to delete record'
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (err.response?.data?.error) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                msg = err.response.data.error
            }
            setError(msg);
            setErrorOpen(true);
        })
    }

    useEffect(() => {
        if (vaccine.type !== 'QR') return

        async function getVaccineRecord() {
            try {
                const response = await axiosAuthedInstance.get<{
                    data: QRCertificate,
                    qr_data: string
                }>(`/user/vaccine-record/${vaccine.id}/`)
                setCertificateRecord(response.data.data)
                setQrData(response.data.qr_data)
                setLoading(false)
            } catch (err) {
                if (isCancel(err)) return

                console.error(err)
                let msg = 'Error getting record'
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (err.response?.data?.error) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    msg = err.response.data.error
                }
                setLoading(false);
                setError(msg);
                setErrorOpen(true);
            }
        }

        void getVaccineRecord()
    }, [vaccine])

    let certificateIssuer = null
    if (!isPatientCertified) {
        certificateIssuer = <ResultItem
            title={isHKCertificate ?
                <MiniTooltipDrawer title="Certificate issuer">
                    <DrawerContent
                        title="Certificate issuer"
                        content="If you have a dose taken in the UK and translated via travel pass into HK, this record will show as HK issued record"
                    />
                </MiniTooltipDrawer>
                :
                'Certificate issuer'}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            value={isQrRecord ?  `${vaccine.issuer}, ${vaccine.country}` : vaccine.issuer}
            extra={<>
                {vaccine.issuer_info}
                {vaccine.issuer_info && vaccine.issuer_email && <br/>}
                {vaccine.issuer_email && (
                    <a href={`mailto:${vaccine.issuer_email}`}>
                        <Typography variant="bodyTextSmallPrimary" component="span">
                            {vaccine.issuer_email}
                        </Typography>
                    </a>
                )}
            </>}
        />
    }

    let product, manufacturer
    if (vaccine.is_generic_vaccine) {

        if (vaccine.vaccine_other) {
            product = `Other (${vaccine.vaccine_other})`
        } else {
            product = 'Unknown'
        }

        manufacturer = 'Unknown'
    } else {
        product = vaccine.medical_product_friendly
        manufacturer = vaccine.manufacturer_friendly
    }

    const vaccineDetailsTable = <PageDescription>
        { vaccine.vaccine_date &&
            <ResultItem title="Vaccination date" value={formatDate(vaccine.vaccine_date)}/>
        }
        {!isQrRecord &&
            <ResultItem
                title={isPatientCertified ? "Provider type" : "Vaccine Provider"}
                value={provider} />
        }

        {/* FIXME this requires IM-1962 for the country code to label mapping */}
        {isPatientCertified &&
            <ResultItem title="Vaccination location" value={vaccine.country} />}

        {certificateIssuer}
        <ResultItem title="Medical product" value={product}/>
        <ResultItem title="Manufacturer" value={manufacturer}/>
        <ResultItem title="Disease(s) targeted" value={vaccine.target_diseases_friendly.join(', ')}/>
        <ResultItem title="Reference number" value={vaccine.reference_no}/>
    </PageDescription>


    let certificateInformation = null

    if (isQrRecord) {
        certificateInformation = <>
            <PageDescription>
                <Typography variant="modalHeader" component="h2">
                    Certificate information
                </Typography>
            </PageDescription>

            {isHKCertificate && certificateRecord ? <>
                {vaccine.partial_vaccine_record &&
                    <PageDescription>
                        <InfoPanel severity="warning" title="Partial HK Covid-19 record">
                            <p>
                                This Hong Kong Covid-19 certificate indicates that {isAccountHolder ? "you have" : "your dependant has"} received {vaccine.dose_count} doses.

                                The certificate only contains details for the most recent {certificateRecord?.vaccines.length} doses, so you may not see all of the doses listed on the records page.
                            </p>
                            <p>
                                {isAccountHolder ? "Your" : "Their"} vaccination status will be calculated on the basis that at least {vaccine.dose_count} doses have been taken.
                            </p>
                        </InfoPanel>
                    </PageDescription>
                }

                <PageDescription>
                    This Hong Kong QR Covid-19 certificate contains information on the
                    following {pluralString('dose', certificateRecord.vaccines.length)}:
                </PageDescription>

                {certificateRecord.vaccines.map((v, i) => (
                    <ListItem
                        key={v.vaccine_date}
                        title={formatDate(v.vaccine_date)}
                        subtitle={vaccineEventName(vaccine)}
                        divider={i + 1 < certificateRecord.vaccines.length}
                    />
                ))}
            </> : null}

            {certificateRecord &&
                <div className={classes.qrWrapper}>
                    <VaccineCertificate certificate={certificateRecord} qrData={qrData}/>
                </div>
            }
        </>
    }

    return (
        <BaseContainer noHeader showCta
            extraComponent={
                <StickyFooterGroup>
                    <Button fullWidth onClick={onClose}>
                        Done
                    </Button>
                </StickyFooterGroup>
            }>

            <div className={classes.iconContainer}>
                <BackButton onClick={onClose} />
                {canDelete &&
                    <IconButton
                        className={classes.deleteIconContainer}
                        id="delete-btn"
                        aria-label="Delete"
                        onClick={() => setShowDeleteDialog(true)}
                    >
                        <TrashIcon className={classes.icon}/>
                    </IconButton>
                }
            </div>

            {<Loading loading={loading}/>}

            <Typography variant="modalHeader" className={classes.vaccineTitle}>
                {vaccine.generic_friendly}
                {isQrRecord &&
                    <span className={classes.titleImages}>
                        {vaccine.country && <CircleFlag country={vaccine.country}/>}
                        <QRCodeIcon className={classes.icon}/>
                    </span>
                }
            </Typography>

            {isPatientCertified &&
                <>
                    <div className={classes.vaccineSubtitle}>
                        <PatientRecordsIcon className={classes.vaccineSubtitleIcon} />
                        <Typography variant="bodyTextLarge">
                            Patient-certified
                        </Typography>
                    </div>
                    <Divider/>
                </>
            }

            {isPaperRecord &&
                <>
                    <div className={classes.vaccineSubtitle}>
                        <ImunisIssuerIcon className={classes.vaccineSubtitleIcon} />
                        <Typography variant="bodyTextLarge">
                            Imunis digitised
                        </Typography>
                    </div>
                    <Divider/>
                </>
            }

            {(vaccine.conflict_generic || vaccine.conflict_disease) &&
                <PageSection>
                    <InfoPanel severity="error" title="Record conflict - vaccine information">
                        {vaccine.conflict_generic ?
                            <>
                                <p>The vaccine information contained in this record conflicts with another record for a same-type vaccination on the same date.</p>
                                <p>Please contact your administering health provider where possible to update your records.</p>
                            </>
                            :
                            <>
                                <p>The vaccination information contained in this record conflicts with another record containing a same-type vaccination on the same date. View your records ‘by time’ to view all potential conflicts.</p>
                                <p>Please contact your administering health provider where possible to correct/update records, allowing Imunis to calculate doses and status reliably.</p>
                            </>
                        }
                    </InfoPanel>
                </PageSection>
            }

            {vaccine.unspecified_product && vaccine.generic_friendly != 'Influenza Unspecified' &&
                <PageSection>
                    <InfoPanel
                        severity={vaccine.unspecified_product_paired === false ? 'error' : 'neutral'}
                        title="Insufficient Information">
                        <p>This record contains insufficient information about the administered vaccination and won’t contribute to your vaccination status. Please contact the administering health provider for assistance in updating your records.</p>
                    </InfoPanel>
                </PageSection>
            }

            {vaccine.unspecified_product && vaccine.generic_friendly == 'Influenza Unspecified' &&
                <PageSection>
                    <InfoPanel
                        severity={vaccine.unspecified_product_paired === false ? 'error' : 'neutral'}
                        title="Insufficient Information">
                        <p>This record contains insufficient information - specifically, the product hemisphere of the administered vaccine. Please contact the administering health provider for assistance in updating your records in order to generate an influenza season status.</p>
                        <p>This record will contribute as a valid dose for children under 9 years of age following a 2-dose schedule.</p>
                    </InfoPanel>
                </PageSection>
            }

            {!provider &&
                <InfoBox className={classes.infoBox}>
                    <Typography variant="bodyTextMediumDark">
                        If you have any record queries/updates, please contact the Certificate Issuer.
                    </Typography>
                </InfoBox>
            }

            {vaccineDetailsTable}

            {certificateInformation}

            <ActionDialog
                cancel
                title={multipleRecs ? 'Delete records?' : 'Delete record?'}
                content={multipleRecs ?
                    'This record contains information for multiple doses. All dose records will be deleted.'
                    :
                    'Are you sure you want to delete this record? This cannot be undone.'
                }
                action_label='Delete'
                action_callback={deleteRecord}
                cancel_label='Cancel'
                cancel_callback={() => setShowDeleteDialog(false)}
                openDialog={showDeleteDialog}
                setOpenDialog={setShowDeleteDialog}
            />
            <ErrorDialog
                error={error}
                setError={setError}
                open={errorOpen}
                setOpen={setErrorOpen}
            />
        </BaseContainer>
    )
}
export default VaccineRecord;

import { Record, User } from "./dtapState";

type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
            type: Key;
        }
        : {
            type: Key;
            payload: M[Key];
        }
}

export enum DtapSteps {
    SET_USER = "SET_USER",
    RECORD_TYPE = "RECORD_TYPE",
    RECORD_DETAILS = "SET_LOCATION",
    COMPLETE = "COMPLETE",
    CONFIRM_UNVACCINATED = "CONFIRM_UNVACCINATED"
}


type DtapPayload = {
    [DtapSteps.SET_USER]: {
        user: User
    }
    [DtapSteps.CONFIRM_UNVACCINATED]: {
        user: User
    }
    [DtapSteps.RECORD_TYPE]: {
    }
    [DtapSteps.RECORD_DETAILS]: {
        product: string;
        last_vaccine_date: string;
    };
    [DtapSteps.COMPLETE]: {
//
    }
};

export type DtapActions = ActionMap<DtapPayload>[keyof ActionMap<DtapPayload>];

export const dtapReducer = (
    state: Record,
    action: DtapActions
) => {
    switch (action.type) {
        case DtapSteps.SET_USER:
            return {
                ...state,
                user: action.payload.user
            }
        case DtapSteps.RECORD_TYPE:
            return {
                ...state
            }
        case DtapSteps.RECORD_DETAILS:
            return {
                ...state,
                product: action.payload.product,
                last_vaccine_date: action.payload.last_vaccine_date,
            }
        case DtapSteps.COMPLETE:
            return {
                ...state,
            }
        default:
            return state;
    }
};

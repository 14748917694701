import { Divider } from "../divider";
import { PageSubsection } from "../page_sections";
import Typography from "../typography";
import { JsonContent } from "../static_content";
import { InfoPanel } from "../info_panel";
import tbJson from "../../content/tb.json";
import { VaccinationStatus } from "../vaccination_progress";
import { formatDate } from "../../utils/common";

type TuberculosisContentProps = {
    status: VaccinationStatus
}

export const tuberculosisStatusContent = ({
    action_status, unavailable_reason
}: TuberculosisContentProps['status']) => {
    switch (action_status) {
        case 'ELIGIBLE':
            return 'You are eligible for vaccination - no qualifying doses held with Imunis.'
        case 'UP_TO_DATE':
            return 'You are fully-vaccinated with one or more qualifying records held with Imunis.'
        case 'MISSED':
            return 'You have no qualifying doses held with Imunis, however the window for effective vaccination has now passed. You may still take the vaccine, but its effectiveness is unclear if taken above the age of 15 years.'
        case 'LATE':
            return 'You are fully-vaccinated, however you were vaccinated outside the target age range. While it is not considered as effective when taken late, it is likely to offer some level of protection.'
        case 'SEEK_ADVICE':
            return 'Due to your vaccination or test history, you are recommended to see medical advice.'
        case "UNAVAILABLE":
            switch (unavailable_reason) {
                case 'CONFLICT':
                    return "Vaccine records indicate a records conflict, therefore a vaccination status cannot be reliably calculated. Please contact the administering health provider to update records, and refer to schedule information for further guidance on dosing."
                case "UNSPECIFIED_PRODUCT":
                    return "Vaccination status is currently unavailable due to a lack of necessary information about the vaccine products/type of products taken."
                case 'DOB_UNAVAILABLE':
                    return 'Status is unavailable due to missing DOB'
            }
    }
}

export const nextDoseDue = ({ next_dose_due }: VaccinationStatus) => {

    if (next_dose_due){

        const dateRegex = new RegExp(/\d{4}-\d{2}-\d{2}/);
        const match = dateRegex.test(next_dose_due);

        if (match){
            const dateStr = formatDate(new Date(next_dose_due))
            return (
                <Typography variant="listTitle">Next dose due from: {dateStr}</Typography>
            )
        } else {
            return (
                <Typography variant="listTitle">Next dose due: {next_dose_due}</Typography>
            )
        }
    }
}

export const TuberculosisContent = ({ status }: TuberculosisContentProps) => (<>
    <PageSubsection>
        <Typography variant="bodyTextMediumDark">
            {tuberculosisStatusContent(status)} [<a target="_blank" rel="noreferrer" href="https://imunis.com/vaccinaton_steps_key.pdf">key</a>]
        </Typography>
    </PageSubsection>

    <PageSubsection>
        {nextDoseDue({...status})}
    </PageSubsection>

    <Divider />

    <JsonContent>
        {tbJson}
    </JsonContent>
</>)

import React, { createContext, useReducer, Dispatch, useEffect } from "react";
import { axiosAuthedInstance } from "../../utils/axiosApi";
import { TDateISODate } from "../../utils/common";
import { DtapActions, dtapReducer, DtapSteps } from "./dtapReducer";
import { isCancel } from "axios";

export type User = {
    uuid: string
    full_name: string
    dob: TDateISODate
    // There are other properties here but we're only interested in the full name
}

export type Record = {
    product: string | null;
    last_vaccine_date: string | null;
};

const initialState = {
    product: null,
    last_vaccine_date: null,
};

const DtapContext = createContext<{
    state: Record
    dispatch: Dispatch<DtapActions>
} | null >(null)

/**
 * Use this instead of React.useContext when consuming DtapProvider.
 * We've initialised the context to null, but we never expect the context to actually be null.
 * See https://stackoverflow.com/a/64266985
 */
export const useDtapContext = (context: typeof DtapContext) => {
    const contextValue = React.useContext(context)
    if (contextValue === null) {
        throw Error("Context has not been Provided!")
    }

    return contextValue;
}

type UserProfileResponse = {
    data: User
}

type DtapProviderProps = {
    /** The UUID of the user we're adding the record to  */
    user: string
}

const DtapProvider: React.FC<DtapProviderProps> = ({ user, children }) => {
    const [state, dispatch] = useReducer(dtapReducer, initialState as unknown as Record)

    return (
        <DtapContext.Provider value={{ state, dispatch }}>
            {children}
        </DtapContext.Provider>
    );
};

export { DtapProvider, DtapContext };

import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import Typography from "../../components/typography"
import TextField from '@material-ui/core/TextField'
import Checkbox from "../../components/checkbox";
import Collapse from '@material-ui/core/Collapse';
import Alert from '../../components/alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link'
import { axiosInstance } from "../../utils/axiosApi";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';
import { BaseContainer } from '../../components/container'
import { DatePicker, toISOString } from "../../components/date_picker"
import { validateName, validateEmail, validatePassword, validateConfirmPassword } from '../../components/text_input'
import { onLogIn } from "../../utils/auth";
import { default as SelectInput, validateRequired } from '../../components/select_input';
import MenuItem from '@material-ui/core/MenuItem'
import { LegalDrawer } from '../../components/legal';
import { IdentityNumberField } from '../../components/identity_number_field';
import { logEvent } from '../../utils/common';
import { StickyFooterGroup } from '../../components/sticky_footer_group';
import { Button, IconButton } from '../../components/button';
import { BackButton } from '../../components/buttons/back_btn';
import { HeaderTitle } from '../../components/page_sections';
import { GlobalContext } from '../../components/context/globalState';

const SignUp = (props) => {
    const { dispatch: dispatchGlobal } = useContext(GlobalContext)
    const [inviteToken, setInviteToken] = useState({})
    const [step, setStep] = useState(0) // 0: User details, 1: password
    const [state, setState] = useState({
        name: '',
        preferredName: '',
        email: '',
        gender: '',
        idType: '',
        password: '',
        confirmPassword: ''
    })
    const [errorMsgs, setErrorMsgs] = useState({
        name: '',
        preferredName: '',
        email: '',
        gender: '',
        idNumber: '',
        password: '',
        confirmPassword: ''
    })
    const [consent, setConsent] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dob, handleDateChange] = useState(null)
    const [dobError, setDobError] = useState('')
    const history = useHistory()

    const { name, preferredName, email, gender, password, confirmPassword } = state

    const location = useLocation()
    useEffect(() => {
        if (location.state?.token && location.state?.type && Object.keys(inviteToken).length === 0) {
            setInviteToken({
                token: location.state.token,
                type: location.state.type,
            })
        }

        const stepFromHistory = location.state?.step || 0
        setStep(stepFromHistory)
    }, [location, inviteToken])

    const onIdNumberChange = (idType, value, error) => {
        setState(prevState => ({
            ...prevState,
            idType,
            idNumber: value
        }))

        setErrorMsgs(prevState => ({
            ...prevState,
            idNumber: error
        }))
    }

    function handleChange(event, type) {
        let error
        const text = event.target.value
        switch (type) {
            case 'name':
                error = validateName(text)
                break;
            case 'email':
                error = validateEmail(text)
                break;
            case 'password':
                error = validatePassword(text)
                if (state.confirmPassword) {
                    setErrorMsgs(prevState => ({
                        ...prevState,
                        confirmPassword: validateConfirmPassword(state.confirmPassword, text)
                    }))
                }
                break;
            case 'confirmPassword':
                error = validateConfirmPassword(text, state.password)
                break;
            case 'gender':
                error = validateRequired(text, "gender")
                break
            default:
                break;
        }

        setErrorMsgs(prevState => ({ ...prevState, [event.target.name]: error }))
        setState(prevState => ({ ...prevState, [event.target.name]: text }))
    }

    async function handleFirstStepCheck() {
        setLoading(true)
        try {
            await axiosInstance.post('/user/email-unique-check/', { email: state.email });
            history.push(location.pathname, { step: 1 })
            setStep(1)
        } catch (error) {
            if (error?.response?.status == 400) {
                setErrorMsgs(prevState => ({
                    ...prevState,
                    email: error.response.data.error_message
                }))
            } else {
                console.error(error)
                setError('Server Error')
                setShowError(true)
            }
        } finally {
            setLoading(false)
        }
    }

    async function handleSubmit(event) {
        if (event) {
            event.preventDefault()
        }

        if (step === 0) {
            handleFirstStepCheck()
            return
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match')
            setShowError(true)
            return
        }

        setLoading(true)
        axiosInstance.post('/user/signup/', {
            data: {
                full_name: state.name,
                preferred_name: state.preferredName,
                email: state.email,
                password: state.password,
                dob: toISOString(dob),
                gender: state.gender,
                id_type: state.idType.toLowerCase(),
                id_number: state.idNumber
            },
            ...inviteToken
        }).then(response => {
            logEvent('self_sign_up')
            if (response.data.uuid) {
                onLogIn({
                    accessToken: response.data.access,
                    refreshToken: response.data.refresh,
                    // We never gave the user the option for this
                    staySignedIn: false,
                })
                dispatchGlobal({
                    type: "SET_USER",
                    payload: response.data,
                })
                if (response.data.token_type == "SharingInvite") {
                    if (response.data.error) {
                        history.push('/invite/error', {
                            type: 'sharing',
                            error: response.data.error
                        })
                    } else {
                        history.push('/sharing/confirm', { token: inviteToken.token })
                    }
                } else {
                    history.push('/home');
                }
            } else {
                history.push('/signup/resend', { email: state.email })
            }
        }).catch(error => {
            setLoading(false)
            if (error.response.status == 400) {
                let response = 'Error Creating an Account'
                if (error.response.data?.error) {
                    response = error.response.data.error
                }
                setError(response);
                setShowError(true)
            } else {
                setError(error.response.data.detail || error.response.data.error || 'Server error. Try again later');
                setShowError(true)
            }
        })
    }

    /** True if step 1/2 has no field errors */
    const validated1 = !(
        errorMsgs.name ||
        errorMsgs.preferredName ||
        errorMsgs.email ||
        errorMsgs.gender ||
        errorMsgs.idNumber ||
        dobError
    )
    const validated2 = !(errorMsgs.password || errorMsgs.confirmPassword)

    /** True if step n has required fields and no field errors */
    // REVIEW: a required field that empty should be flagged as an error itself?
    const enabled1 = (
        name &&
        preferredName &&
        email &&
        gender &&
        consent &&
        dob &&
        validated1
    )
    const enabled2 = password && confirmPassword && validated2

    const maxDate = new Date()
    maxDate.setFullYear(maxDate.getFullYear() - 18)


    let title, content
    switch (step) {
        case 0:
            title = "Hello! Get started with your information"
            content = <>
                <TextField
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="Name"
                    fullWidth
                    error={!!errorMsgs.name}
                    helperText={errorMsgs.name || 'Use name and format on HKID/ID document.'}
                    margin="normal"
                    value={name}
                    onChange={(event) => handleChange(event, 'name')}
                    required
                />
                <TextField
                    id="preferredName"
                    name="preferredName"
                    label="Display Name"
                    placeholder="Display Name"
                    fullWidth
                    error={!!errorMsgs.preferredName}
                    helperText={errorMsgs.preferredName || 'How we refer to you - only in the Imunis App.'}
                    margin="normal"
                    value={preferredName}
                    onChange={(event) => handleChange(event, 'name')}
                    required
                />
                <TextField
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Email address"
                    fullWidth
                    margin="normal"
                    value={email}
                    error={!!errorMsgs.email}
                    helperText={errorMsgs.email || 'Used to sign in to your Imunis account.'}
                    onChange={(event) => handleChange(event, 'email')}
                    required
                />
                <IdentityNumberField
                    fullWidth
                    margin="normal"
                    onChange={onIdNumberChange}
                    error={errorMsgs.idNumber}
                />
                <SelectInput
                    name="gender"
                    label="Gender"
                    fullWidth
                    margin="normal"
                    value={gender}
                    onChange={(event) => handleChange(event, 'gender')}
                    error={!!errorMsgs.gender}
                    helperText={errorMsgs.gender}
                    required
                >
                    <MenuItem value="M">Male</MenuItem>
                    <MenuItem value="F">Female</MenuItem>
                </SelectInput>
                <DatePicker
                    maxDate={maxDate}
                    initialFocusedDate={maxDate}
                    disableFuture
                    fullWidth
                    id="dob"
                    name="dob"
                    margin="normal"
                    openTo="year"
                    label="Date of birth"
                    views={["year", "month", "date"]}
                    placeholder={'dd MMM yyyy'}
                    value={dob}
                    onError={(err) => setDobError(err)}
                    helperText={dobError ||
                        <span data-action="Date of birth">
                            Imunis account holder must be 18 or above.
                            Information is used for records matching and vaccination status. This cannot be changed once entered.
                        </span>
                    }
                    maxDateMessage="Imunis account holder must be 18 or above. Information is used for records matching & vaccination status."
                    onChange={handleDateChange}
                    required
                />
                <Checkbox
                    checked={consent}
                    name="consent"
                    data-action="Consent checkbox"
                    onChange={() => setConsent(!consent)}
                    label={
                        <Typography variant="bodyTextSmall">
                            By ticking the box, I confirm that I have read and I agree to the
                            {' '}
                            <LegalDrawer document="privacy_policy">
                                <Link>Privacy Notice</Link>
                            </LegalDrawer>
                            {' '} and {' '}
                            <LegalDrawer document="service_agreement">
                                <Link>Account Service Agreement</Link>
                            </LegalDrawer>.
                        </Typography>
                    }
                />
            </>
            break
        case 1:
            title = "Let's make it secure"
            content = <>
                {/* For browser autocomplete / password managers */}
                <input hidden type="text" autoComplete="username" value={email} readOnly />
                <TextField
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    autoComplete="new-password"
                    fullWidth
                    margin="normal"
                    error={!!errorMsgs.password}
                    helperText={errorMsgs.password}
                    value={password}
                    onChange={(event) => handleChange(event, 'password')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    aria-label="Toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={(event) => { event.preventDefault() }}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    required
                />
                <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showConfirm ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    fullWidth
                    margin="normal"
                    value={confirmPassword}
                    error={!!errorMsgs.confirmPassword}
                    helperText={errorMsgs.confirmPassword}
                    onChange={(event) => handleChange(event, 'confirmPassword')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    aria-label="Toggle password visibility"
                                    onClick={() => setShowConfirm(!showConfirm)}
                                    onMouseDown={(event) => { event.preventDefault() }}
                                    edge="end"
                                >
                                    {showConfirm ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    required
                />
            </>
            break
    }

    return (
        <BaseContainer {...props} noHeader showCta extraComponent={step < 2 &&
            <StickyFooterGroup>
                <Button
                    disabled={!(step === 0 && enabled1) && !(step === 1 && enabled2)}
                    loading={loading}
                    fullWidth
                    primary
                    form="sign-up"
                    type="submit">
                    Continue
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton />

            <HeaderTitle>
                {title}
            </HeaderTitle>

            <Collapse in={showError}>
                <Alert
                    severity='error'
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setShowError(false)
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {error}
                </Alert>
            </Collapse>

            <form id="sign-up" onSubmit={handleSubmit}>
                {content}
            </form>
        </BaseContainer>
    )
}

export default SignUp

import ImunisIssuerIcon from "../../assets/icons/imunis_issuer"
import MultipleRecordsIcon from "../../assets/icons/multiple_records"
import PatientRecordsIcon from "../../assets/icons/patient_records"
import { TDateISODate } from "../../utils/common"
import { ListItemIcon } from "../../components/lists/list_item"
import Typography from "../../components/typography"
import theme from "../../styles/theme"


/**
 * A vaccine event key has the form <generic_id>,<date>
 * e.g. "IMMU-GENERIC-BCG,1979-01-01"
 */
export type VaccineEventKey = `${string},${TDateISODate}`

/**
 * Primary key of a vaccine record. UUIDs, apart from Clinic vaccine records which use integers
 */
export type VaccineRecordKey = string

export type VaccineEvent = {
    source_records: string[]

    /**
     * Set to true if there are multiple sources for this event with conflicting products. In this
     * case, all source records will be listed independantly as separate vaccine events.
     */
    conflict_generic: boolean

    /*
     * Present and set to true if this vaccine event overlaps with another by the same disease x date
     * Normally you don't see to vaccination events for the same disease given on the same day
     * We will dsiplay these records as conflicted on the UI
     */
    conflict_disease: boolean

    /*
     * Unspecified products are items which lack sufficient detail in order to be used by Imunis.
     * These are either displayed as greyed out when paired, to denote that Imunis doesn't recognise
     * them in calculations, or in red when unpaired to denote that the record will prevent the rules
     * engine from giving a status
     */
    unspecified_product: boolean
    unspecified_product_paired: boolean

    generic_friendly: string
    is_generic_vaccine: boolean
    resolved_type: string
    target_diseases_friendly: string[]
    vaccine_date: TDateISODate
    medical_product_friendly: string
}

export type VaccineRecord = {
    country?: string
    date_added?: string
    dose_count?: string
    generic: string
    is_generic_vaccine?: boolean
    issuer_info?: string
    issuer_phone?: string
    issuer_email?: string
    /**
     * Depending on type:
     * QR:      <name of issuer (eg "NHS Digital")>|"HKSARG"
     * CLINIC:  <name of clinic>
     * PATIENT: "Patient-certified"
     * PAPER:   "Imunis"
     */
    issuer: string
    manufacturer_friendly?: string
    medical_product_friendly?: string
    partial_vaccine_record?: string
    /**
     * Provider, or "Unknown"
     */
    provider: string
    reference_no?: string
    type: 'QR' | 'CLINIC' | 'PATIENT' | 'PAPER'
    sub_type?: "DTAP"
    vaccine_other?: string
    vaccine_date: TDateISODate
}

export type SourceRecords = {
    [id: string]: VaccineRecord
}

export const VaccineEventIcon = (
    {
        source_records: sourceRecords,
    }: VaccineEvent,
    sources: SourceRecords) => {

    let icon = null
    if (sourceRecords.length > 1) {
        icon = MultipleRecordsIcon
    } else {
        switch (sources[sourceRecords[0]].type) {
            case 'PATIENT':
                icon = PatientRecordsIcon
                break
            case 'PAPER':
                icon = ImunisIssuerIcon
                break
        }
    }

    if (icon) {
        icon = <ListItemIcon icon={icon} />
    }

    return icon
}

export const vaccineEventName = (
    vaccineEvent: VaccineEvent,
    sources?: SourceRecords,
) => {
    const {
        is_generic_vaccine,
        medical_product_friendly,
        generic_friendly,
        source_records,
    } = vaccineEvent

    let medicalProduct = medical_product_friendly
    if (is_generic_vaccine) {
        const other = source_records.length === 1 && sources && sources[source_records[0]].vaccine_other
        if (other) {
            medicalProduct = `Other (${other})`
        } else {
            medicalProduct = 'Unknown Product'
        }
    }

    return `${generic_friendly} - ${medicalProduct}`
}

export const conflictLabel = ({conflict_generic, conflict_disease}: VaccineEvent) => {
    if (conflict_generic || conflict_disease) {
        return <>
            {' '}
            <Typography component="span" variant="bodyTextMediumBold" style={{
                color: theme.palette.error.main
            }}>
                (Record Conflict)
            </Typography>
        </>
    }
}

import { login_expiration_time, LOCAL_STORAGE_STAY_SIGNED_IN } from "./constants";
import { axiosAuthedInstance } from "./axiosApi";
import mixpanel from "mixpanel-browser";

export const onTokenRefresh = async ({accessToken, refreshToken}: {
    accessToken: string
    refreshToken: string
}) => {
    axiosAuthedInstance.defaults.headers['Authorization'] = `JWT ${accessToken}`

    localStorage.setItem('access_token', accessToken)
    localStorage.setItem('refresh_token', refreshToken)
}

export const onLogIn = ({accessToken, refreshToken, lastLogin, staySignedIn}: {
    accessToken: string
    refreshToken: string
    lastLogin?: string
    staySignedIn: boolean
}) => {
    axiosAuthedInstance.defaults.headers['Authorization'] = `JWT ${accessToken}`;

    localStorage.setItem(LOCAL_STORAGE_STAY_SIGNED_IN, String(staySignedIn))
    localStorage.setItem('access_token', accessToken)
    localStorage.setItem('refresh_token', refreshToken)
    localStorage.setItem('first_visit', lastLogin ? "false" : "true")
    if (!staySignedIn) {
        updateSessionExpiry()
    }
}

// todo: might need to blacklist used tokens
// we cleared the localStorage and Auth header in the constructor function(which executes before render function),
// so that when the header is rendered, the loggedIn variable would have the correct value, which is false,
// and then the notifications count api wouldn't be called
export const onLogOut = () => {
    clearStorage()
    delete axiosAuthedInstance.defaults.headers['Authorization']

    // Docs say:
    //
    // > Clear a previously set user with the `removeUser` API.
    // > All RUM events collected afterwards do not contain user information.
    //
    // i.e. once `removeUser` is called, a user will never get re-associated to the RUM session,
    // even when `setUser` is called again after logging back in.
    // We probably would prefer to tag the session with the most recent user rather than no user at
    // all in cases like these.
    // datadogRum.removeUser()

    const superProps = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        display_mode: mixpanel.get_property('display_mode')
    }
    mixpanel.reset()
    mixpanel.register(superProps)
}


// Manually complied list of keys we may have created in localStorage
const storageKeys = [
    'access_token',
    'refresh_token',
    'login_expiry',
    LOCAL_STORAGE_STAY_SIGNED_IN,
    'uuid',
    'email',
    'full_name',
    'preferred_name',
    'date_of_birth',
    'feature_flags',
    'missing_dtap', // Set if the account holder is missing dtap records and hasn't self certified
    'missing_dtap_reminder', // Set if the popup is squashed
    'notification_count',
    'first_visit',
    'pending_connections_reminder',
]

/**
 * Remove all the keys from localStorage that we may have set.
 * Don't do `localStorage.clear()` -- that will wipe keys which might be used by other libraries
 * (e.g. Mixpanel) */
export const clearStorage = () => storageKeys.map((item) => localStorage.removeItem(item))

export const updateSessionExpiry = () => {
    // only refresh the session expiry if LOCAL_STORAGE_STAY_SIGNED_IN is false
    if (localStorage.getItem(LOCAL_STORAGE_STAY_SIGNED_IN) == 'false') {
        console.log('Login session expiry refreshed');
        // @ts-expect-error: TODO: cast argument to string
        localStorage.setItem('login_expiry', (Date.now() + login_expiration_time));
    }
}

export const isSessionExpired = () => {
    if (!localStorage.getItem('login_expiry')) {
        return false;
    // @ts-expect-error: handle possible null value for localStorage.getItem('login_expiry')
    } else if (localStorage.getItem('login_expiry') && Date.now() < parseInt(localStorage.getItem('login_expiry'))) {
        return false;
    } else {
        console.log('Time now', Date.now());
        console.log('Login expiry', localStorage.getItem('login_expiry'));
        return true;
    }
}

/**
 * Whether a user is logged in or not now depends on two criteria:
 * 1. localStorage.getItem('access_token') has value
 * 2. login session isn't expired yet(if login_expiry is set in localStorage)
 *
 * @returns boolean
 */
export const isLoggedIn = () => {
    if (!isSessionExpired() && localStorage.getItem('access_token')) {
        return true;
    }
    return false;
}

/** @deprecated Access user details from the global context */
export const getUserFullName = () => {
    return localStorage.getItem('full_name');
}

/** @deprecated Access user details from the global context */
export const getPreferredName = () => {
    return localStorage.getItem('preferred_name');
}

/** @deprecated Access user details from the global context */
export const getUUID = () => {
    return localStorage.getItem('uuid')
}

/** @deprecated Access user details from the global context */
export const getEmail = () => {
    return localStorage.getItem('email')
}

/** @deprecated Access user details from the global context */
export const getDateOfBirth = () => {
    return localStorage.getItem('date_of_birth')
}

/** @deprecated Access user details from the global context */
export const getFeatureFlags = () => {
    const feature_flags = localStorage.getItem('feature_flags')
    if (feature_flags == null){
        return []
    } else {
        return JSON.parse(feature_flags)
    }
}

/** @deprecated Access user details from the global context */
export const getMissingDtap = () => {
    return localStorage.getItem('missing_dtap')
}

import { StaticHTML } from "./static_content";

export const diseaseGroupInfo = (diseaseGroupName: string) => {
    let title, intro, content
    switch (diseaseGroupName) {
        case 'Featured HK Routine':
            title = 'Featured HK Routine'
            intro = 'These are vaccinations routinely offered in Hong Kong, and where Imunis is currently able to digitally calculate your status'
            content = <StaticHTML>
                <p>
                    Imunis allows you to view your vaccine records by chronology, and by disease against which they protect. Diseases are shown as 'Featured Hong Kong Routine', 'General Hong Kong Routine', and 'Travel/Other'.
                </p>
                <h4>
                    Featured Hong Kong Routine
                </h4>
                <p>
                    These are vaccinations routinely offered in Hong Kong, and where Imunis is currently able to digitally calculate your status by interpreting the vaccinations you've registered relative to the recommendations issued in Hong Kong (a 'featured' disease). This currently includes Covid-19, Influenza (for both Northern and Southern hemisphere), Measles, Tuberculosis, and HPV.
                </p>
                <p>
                    Imunis considers age, type of vaccine and intervals between doses when calculating whether you have completed your course or are recommended to take any further action to protect yourself. We will be adding further diseases to this list, as well as additional functionality such as capturing test results and infection history.
                </p>
            </StaticHTML>
            break;
        case 'General HK Routine':
            title = 'General HK Routine'
            intro = 'These are vaccinations routinely offered in Hong Kong, but without an Imunis digital status calculation'
            content = <StaticHTML>
                <p>
                    These are vaccinations routinely offered in Hong Kong, but without an Imunis digital status calculation. We'll be adding digital status to more vaccinations shortly.
                </p>
                <p>
                    You can view the vaccine records uploaded so you can more easily understand how each vaccination protects against different diseases, which can be complex particularly in the case of combination vaccines.
                </p>
            </StaticHTML>
            break;
        case 'Travel / Others':
            title = 'Travel / Others'
            intro = 'These are vaccinations which may be offered privately and by travel clinics'
            content = <StaticHTML>
                <p>
                    These are vaccinations which may be offered privately and by travel clinics, but which are not typically offered on a routine basis in Hong Kong and don't currently have an Imunis digital status calculation. We'll be adding digital status to more vaccinations shortly.
                </p>
            </StaticHTML>
            break;
        case 'Occupational Health Required':
            title = 'Occupational Health Required'
            intro = 'Vaccinations/immunity proofs that are required, specific to role, under the latest occupational health guidelines'
            content = <StaticHTML>
                <p>
                    Imunis allows you to view your vaccination/immunity proof records and
                    status relative to the latest occupational health guidelines.
                    These will be based on role-specific criteria managed by
                    the occupational health manager.
                    <br/><br/>
                    Required vaccinations/immunity proofs are those for which you must either be
                    up-to-date, or for which you will need to have a certified medical exemption,
                    in order to comply with the latest occupational health guidelines.
                    These help protect you from occupational risk, and your customers/patients.
                </p>
            </StaticHTML>
            break;
        case 'Occupational Health Recommended':
            title = 'Occupational Health Recommended'
            intro = 'Vaccinations/immunity proofs that are recommended, specific to role, under the latest occupational health guidelines'
            content = <StaticHTML>
                <p>
                    Imunis allows you to view your vaccination/immunity proof records and
                    status relative to the latest occupational health guidelines.
                    These will be based on role-specific criteria managed by
                    the occupational health manager.
                    <br/><br/>
                    Recommended vaccinations/immunity proofs are those deemed under
                    occupational guidelines to offer protection
                    to you and/or your patients/customers, but whereby there is
                    no official occupational health requirement for you to be up-to-date or to secure
                    a vaccination exemption certificate.
                </p>
            </StaticHTML>
            break;
        case 'Occupational Health Other':
            title = 'Occupational Health Other'
            intro = 'Vaccinations/immunity proofs that may be recommended as part of routine vaccination programmes or for travel purposes'
            content = <StaticHTML>
                <p>
                    Imunis allows you to view your vaccination/immunity proof records and
                    status relative to the latest occupational health guidelines.
                    These will be based on role-specific criteria managed by
                    the occupational health manager.
                    <br/><br/>
                    Other category includes any vaccination that does not come under a recommended
                    or routine occupational health guideline.
                </p>
            </StaticHTML>
            break;
        default:
            break;
    }
    return { title, intro, content }
}

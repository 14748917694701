import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar';
import { useState, useEffect, useRef } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
// We need to import the server stylesheet explicity so the module is detected
import styles from './styles/main.css'; // eslint-disable-line @typescript-eslint/no-unused-vars
import { ThemeProvider } from '@material-ui/core/styles';
import imunisTheme from './styles/theme'
import { clearStorage, getUUID, isLoggedIn, updateSessionExpiry } from './utils/auth';
import { GlobalProvider } from "./components/context/globalState";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { App as CapacitorApp } from '@capacitor/app';
import { scrubbedURL } from './utils/common';
import mixpanel from 'mixpanel-browser';

// Intro
import { Splash } from "./pages/splash";
// Pages
import Homepage from './pages/homepage';
import Dashboard from './pages/dashboard';
import NotFound from "./pages/not_found";
import Notifications from "./pages/notifications";
// Signup
import Login from './pages/user/login';
import SignUp from "./pages/user/signup";
import Logout from './pages/user/logout';
import VerifyEmail from './pages/user/verify_email';
import { ResendEmail } from "./components/resend_email";
import ForgotPassword from "./pages/user/forgot_password";
import ResetPassword from "./pages/user/reset_password";
// Connections
import Connections from "./pages/connections/connections";
import AcceptConnectionInvite from "./pages/connections/accept_invitation";
import { OnboardAdult } from './pages/connections/onboard_adult';
import { OnboardDependant } from './pages/connections/onboard_dependant';
// Records
import Records from "./pages/records/records";
import RecordsUser from "./pages/records/user";
// Sharing
import Sharing from "./pages/sharing/sharing";
import ShareQR from "./pages/sharing/share_qr";
import SelectSharingMethod from "./pages/sharing/select_sharing_method";
import ShareEmail from "./pages/sharing/share_email";
import ShareConfirm from "./pages/sharing/share_confirm";
import InviteError from "./pages/invite_error";
// Health
import AddCovidVaccine from "./pages/health/add_covid_vaccine";
// Family
import DependantScanner from "./pages/family/scan_dependant";
import VerifyShare from './pages/family/verify_share';
import AddDependants from "./pages/family/add_dependants";
// Discover
import Discover from './pages/discover/discover'
import VaccinationSchedule from './pages/discover/vaccination_schedule'
// Travel
import {TravelItinerary, ItineraryVaccines, TravelAddDestination} from './pages/travel/itinerary'
// Test
import Templates from './pages/test_pages/templates';
import TestSharingDependants from "./pages/test_pages/test_share_dependant";
import TestSendNotification from "./pages/test_pages/test_send_notification";
import {PageScrollingDemo} from "./pages/test_pages/page_scrolling/test_page_scrolling";
import {NoShadowDemo} from "./pages/test_pages/page_scrolling/test_no_shadow_scrolling";
import {ElevationScrollingDemo} from "./pages/test_pages/page_scrolling/test_elevation_scrolling";
import {FloatButtonDemo} from "./pages/test_pages/page_scrolling/test_float_button_scolling";
import TestCreatePupil from "./pages/test_pages/test_create_pupil";
import {ShowNavCta, ShowCtaOnly, ShowNavOnly} from "./pages/test_pages/test_bottom";
import TestClinicInvite from "./pages/test_pages/test_clinic_invite";
import TestGenerateError from "./pages/test_pages/test_generate_error";
import TestResetIntro from "./pages/test_pages/test_reset_intro";

import { AddRecord } from './pages/records/patient_certified/add_record';
import { AddDtap } from './pages/records/patient_certified/add_dtap';
import { AddVaccineRecord, AddPaperRecords } from './pages/add_vaccine_record'
import { AddPaperRecords as AddPaperRecordsWizard, Step as PaperRecordStep } from './pages/records/paper/new';
import { SubmissionHistory } from './pages/records/paper/history';
import { SubmissionView } from './pages/records/paper/view';

import Editor from "./pages/editor";
import { AccountSettings } from './pages/user/account_settings';
import { InvitedDependantsDashboard } from './pages/invited_dependants';
import { MissingDTaP } from './pages/missing_dtap';
import { initialiseInterceptors } from './utils/axiosApi';
import ActionDialog from './components/dialogs/action_dialog';
import { TestDeepLinks } from './pages/test_pages/test_deep_links';

if (Capacitor.getPlatform() === 'android') {
    // Ensuring that on android the statusbar doesn't overlap the webview
    // We won't dynamically change the android statusbar since it's not overlaid
    void StatusBar.setOverlaysWebView({ overlay: false })
}

/** Requires a logged in user to access the route. Redirects to the log in page otherwise. */
function authPage(Destination, props) {
    // If a user is logged in but we have no UUID, then their session information
    // has likely been corrupted by a race condition. Remove their session to force
    // them to login again and reset their state
    if (isLoggedIn() && !getUUID()) {
        // Wipe local storage, effectively logging the user out
        // Calls to isLoggedIn will now return false
        clearStorage()
    }
    if (!isLoggedIn()) {
        // this prevents the user going to a page with null uuid in the url after logging in
        // the null uuid is an edge case when user has multiple tabs, logouts in one of them, then try to continue to action in other tabs
        if (props.location.pathname.includes('/null')) {
            return <Redirect to={'/login'} />
        }
        return <Redirect to={'/login?next='+props.location.pathname} />
    } else {
        // refreshes user's login session expiry time
        updateSessionExpiry();
        return <Destination {...props} />
    }
}

/** Prevents a logged in user from accessing the route. Redirects to the User dashboard otherwise. */
function authed(Destination, props) {
    if(isLoggedIn()) {
        // refreshes user's login session expiry time
        updateSessionExpiry();
        return <Redirect to={'/home'} />
    } else {
        return <Destination {...props} />
    }
}

/** Handles iOS native app routing */
const AppUrlListener = () => {
    const history = useHistory()

    useEffect(() => {
        CapacitorApp.addListener('appUrlOpen', (event) => {
            const url = new window.URL(event.url)
            history.push(url.pathname)
        })

        return () => {
            CapacitorApp.removeAllListeners()
        }
    }, [])

    return null
}

/**
 * Provide the Axios interceptors with the history instance so we can do in-app navigation.
 */
const AxiosInterceptors = () => {
    const history = useHistory()

    useEffect(() => {
        initialiseInterceptors(history)
    }, [history])

    return null
}

/**
 * A dummy component (returns `null`) that just installs an app-wide hook in order to send page view
 * events to analytics (GA, Mixpanel).
 *
 * We do this manually in order to handle masking sensitive data (invite tokens) and to de-duplicate
 * URLs that contain user UUIDs.
 */
const PageTracker = () => {
    const location = useLocation()

    useEffect(() => {

        /**
         * Update page location -- any other events sent to GA on the same page will include the
         * `page_location` value set here. Note GA uses the full URL of the page for `page_location`.
         *
         * Ensure GA is configured to not track page views automatically:
         * https://support.google.com/analytics/answer/9216061
         *
         */
        window.gtag("set", {
            page_location: document.location.origin + scrubbedURL(`${location.pathname}${location.search}`),
        });

        // https://developers.google.com/tag-platform/gtagjs/reference/events#page_view
        window.gtag('event', 'page_view', {
            page_title: document.title,
            user_agent: navigator.userAgent,
        })

        /**
         * Send page views to Mixpanel (auto tracking is disabled by default).
         * Override Mixpanel's `current_url(path|search)` keys with their sanitised versions.
         *
         * See:
         * https://docs.mixpanel.com/docs/tracking/reference/javascript#tracking-page-views
         * https://github.com/mixpanel/mixpanel-js/blob/master/src/utils.js#L1649
         */
        mixpanel.track_pageview({
            current_url_path: scrubbedURL(location.pathname),
            current_url_search: scrubbedURL(location.search),
        })

    }, [location.pathname, location.search])

    return null
}

/**
 * Define frontend application routes here
 * By convention paths should be param-cased and not end with a trailing slash
 */
const AppRoutes = () => {
    const { pathname, search, hash } = useLocation()

    // A <Switch> renders the first child <Route> or <Redirect> that matches the location.
    return (
        <Switch>
            {/** Drops trailing slash from paths */}
            <Redirect exact strict from="/*/" to={`${pathname.slice(0, -1)}${search}${hash}`} />

            {/* Records pages */}
            <Route exact path={"/records"} render={(props) => authPage(Records, props)}/>
            <Route exact path={"/records/:uuid/:tab(health|profile|vaccine|past-records)"} render={(props) => authPage(RecordsUser, props)}/>
            {/* Health Pages */}
            <Route exact path={"/scan"} render={(props) => authPage(AddCovidVaccine, props)}/>
            <Route exact path={"/add-record/:uuid/:step(location|hemisphere|date|product|summary|hemisphere-cannot-proceed|date-cannot-proceed|location-cannot-proceed|record-duplicate)"}
                render={(props) => authPage(AddRecord, props)}
            />

            <Route exact path={"/add-dtap/:uuid/:step(record_type|record_details|ask_later|confirm_unvaccinated|dont_qualify)"}
                render={(props) => authPage(AddDtap, props)}
            />

            <Route exact path={"/add-vaccine-record"} render={(props) => authPage(AddVaccineRecord, props)} />

            <Route exact path={"/paper-records/new"} render={(props) => authPage(AddPaperRecords, props)} />
            <Route exact
                path={`/paper-records/new/:step(${Object.values(PaperRecordStep).join('|')})`}
                render={(props) => authPage(AddPaperRecordsWizard, props)} />
            <Route exact path="/paper-records/history" render={(props) => authPage(SubmissionHistory, props)} />

            <Route exact path="/paper-records/submission/:id"
                render={(props) => authPage(SubmissionView, props)} />
            <Route exact path="/paper-records/submission/:id/page/:pageNumber"
                render={(props) => authPage(SubmissionView, props)} />

            {/* Family Pages */}
            <Route exact path={"/dependants/scan"} render={(props) => authPage(DependantScanner, props)}/>
            <Route exact path={"/dependants/add"} render={(props) => authPage(AddDependants, props)} />
            {/* Sharing pages */}
            <Route exact path={"/sharing/invite/:token"} component={VerifyShare}/>
            <Route exact path={"/sharing/confirm"} render={(props) => authPage(ShareConfirm, props)} />
            <Route exact path={"/sharing/send/email"} render={(props) => authPage(ShareEmail, props)}/>
            <Route exact path={"/sharing/send/qr"} render={(props) => authPage(ShareQR, props)}/>
            <Route exact path={"/sharing/send"} render={(props) => authPage(SelectSharingMethod, props)}/>
            <Route exact path={"/sharing"} render={(props) => authPage(Sharing, props)}/>
            {/* Connections pages */}
            <Route exact path={"/connections"} render={(props) => authPage(Connections, props)}/>
            <Route exact path={"/connection/invite/:token"} component={AcceptConnectionInvite}/>
            <Route exact path={"/connection/adult/:step"} component={OnboardAdult}/>
            <Route exact path={"/connection/dependant/:step"} render={(props) => authPage(OnboardDependant, props)}/>
            {/* Sign up pages */}
            <Route exact path={"/signup"} component={SignUp}/>
            <Route exact path={"/signup/resend"} component={ResendEmail} />
            <Route exact path={"/verify-email/:token"} component={VerifyEmail}/>
            <Route exact path={"/login"} render={(props) => authed(Login, props)}/>
            <Route exact path={"/logout"} component={Logout}/>
            <Route exact path={"/forgot-password"} component={ForgotPassword}/>
            <Route exact path={"/reset-password/:uuid/:token"} component={ResetPassword}/>
            {/* Account setting pages */}
            <Route exact path={"/account-settings"} render={(props) => authPage(AccountSettings, props)}/>
            {/* Home pages*/}
            <Route exact path={"/notifications"} render={(props) => authPage(Notifications, props)}/>
            <Route exact path={"/home"} render={(props) => authPage(Dashboard, props)} />
            <Route exact path={"/"} render={(props) => authed(Homepage, props)} />
            <Route exact path="/pending-connections" render={(props) => authPage(InvitedDependantsDashboard, props)} />
            <Route exact path="/missing-dtap" render={(props) => authPage(MissingDTaP, props)} />
            {/* Discover */}
            <Route exact path={"/discover"} render={(props) => authPage(Discover, props)}/>
            <Route exact path={"/discover/schedule"} render={(props) => authPage(VaccinationSchedule, props)} />
            {/* Travel Vaccines */}
            <Route exact path={"/travel/itinerary"} render={(props) => authPage(TravelItinerary, props)}/>
            <Route exact path={"/travel/vaccines"} render={(props) => authPage(ItineraryVaccines, props)}/>
            <Route exact path={"/travel/add-destination"} render={(props) => authPage(TravelAddDestination, props)}/>

            {/* Testing / internal pages */}
            <Route exact path={"/_/editor"} component={Editor} />
            <Route exact path={'/templates'} component={Templates} />
            <Route exact path={"/test-share-dependants"} render={(props) => authPage(TestSharingDependants, props)}/>
            <Route exact path={"/test-send-notification"} render={(props) => authPage(TestSendNotification, props)}/>
            <Route exact path={"/test-page-scrolling"} component={PageScrollingDemo} />
            <Route exact path={"/test-no-shadow-scrolling"} component={NoShadowDemo} />
            <Route exact path={"/test-elevation-scrolling"} component={ElevationScrollingDemo} />
            <Route exact path={"/test-float-button-scrolling"} component={FloatButtonDemo} />
            <Route exact path={"/test-create-pupil"} component={TestCreatePupil} />
            <Route exact path={"/test-nav"} component={ShowNavOnly} />
            <Route exact path={"/test-cta"} component={ShowCtaOnly} />
            <Route exact path={"/test-nav-cta"} component={ShowNavCta} />
            <Route exact path={"/test-clinic-invite"} render={(props) => authPage(TestClinicInvite, props)}/>
            <Route exact path={"/test-generate-error"} render={(props) => authPage(TestGenerateError, props)}/>
            <Route exact path={"/test-deep-links"} render={(props) => authPage(TestDeepLinks, props)}/>
            <Route exact path={"/test-reset-intro"} render={(props) => authPage(TestResetIntro, props)}/>

            {/* Error Pages */}
            <Route exact path={"/invite/error"} component={InviteError}/>
            <Route component={NotFound}/>
        </Switch>
    )
}

const App = ({splash}) => {
    const isHomePage = window.location.pathname === '/'
    const [showSplash, setSplash] = useState(splash)
    const [exitPrompt, setExitPrompt] = useState(false)
    const exitPromptCallbackRef = useRef()

    useEffect(() => {
        setTimeout(() => {
            setSplash(false)
        }, imunisTheme.transitions.duration.short)
    });

    useEffect(() => {
        // Set native status bar color for iOS
        if (Capacitor.getPlatform() === 'ios') {
            void StatusBar.setStyle({ style: Style.Light });
        }

        const jssStyles = document.getElementById('jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    if (showSplash) {
        return <Splash visible={showSplash}/>
    }

    return <>
        <Splash visible={showSplash} noBackground={isHomePage}/>
        <ThemeProvider theme={imunisTheme}>
            <GlobalProvider>
                <CssBaseline/>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Router getUserConfirmation={(message, callback) => {
                        setExitPrompt(message)
                        exitPromptCallbackRef.current = callback
                    }}>
                        <ActionDialog
                            title="Leave page?"
                            content={exitPrompt}
                            openDialog={Boolean(exitPrompt)}
                            setOpenDialog={setExitPrompt}
                            action_label="OK"
                            action_callback={() => exitPromptCallbackRef.current(true)}
                            cancel_callback={() => exitPromptCallbackRef.current(false)}
                            close_callback={() => exitPromptCallbackRef.current(false)}
                            cancel={true} />

                        <AppUrlListener/>
                        <AxiosInterceptors/>
                        <PageTracker/>
                        <AppRoutes/>
                    </Router>
                </MuiPickersUtilsProvider>
            </GlobalProvider>
        </ThemeProvider>
    </>;
}

export default App;

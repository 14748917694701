import { makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, TextButton } from "../../../components/button"
import { BackButton } from "../../../components/buttons/back_btn"
import { BaseContainer } from "../../../components/container"
import { DtapSteps } from "../../../components/context/dtapReducer"
import { DtapContext, useDtapContext } from "../../../components/context/dtapState"
import { DatePicker, toISOString } from "../../../components/date_picker"
import { HeaderTitle } from "../../../components/page_sections"
import Typography from "../../../components/typography"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import { isLoggedIn, getUserFullName, getEmail, getUUID } from "../../../utils/auth";
import { Step as PaperRecordStep } from "../../records/paper/new"
import { axiosAuthedInstance } from "../../../utils/axiosApi"
import ErrorDialog from "../../../components/dialogs/error_dialog"

const useStyles = makeStyles((theme) => ({
    textButton: {
        marginTop: theme.spacing(2),
    }
}))

type ConfirmNoDtapResponse = {
}

export const DontQualify = () => {
    const classes = useStyles()
    const history = useHistory()

    const { state, dispatch } = useDtapContext(DtapContext)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)

    function handleSubmit() {

        setLoading(true)

        axiosAuthedInstance.post<ConfirmNoDtapResponse>(`/user/${getUUID()}/confirm-no-dtap-under-18/`)
            .then((response) => {
                localStorage.setItem('missing_dtap', "false")
                history.replace(`/home`)
            }).catch(error => {
                console.error(error)
                setError('Error adding records.');
                setShowError(true)
            }).finally(() => {
                setLoading(false)
            })
        }

    return (
        <BaseContainer noHeader showCta extraComponent={
            <StickyFooterGroup>
                <Button primary fullWidth onClick={handleSubmit} loading={loading}>
                    Close
                </Button>
            </StickyFooterGroup>
        }>
            <BackButton/>

            <HeaderTitle>
                Diphtheria, Tetanus, Pertussis & Polio self-certification
            </HeaderTitle>

            <p>
                If you have access to your childhood vaccination records, please add them
                to your account using the ‘Digitise paper records’ option on the home page.
            </p>
            <p>
                If you don’t have access to your records and don’t believe you qualify to
                self-certify, it’s likely that your current Imunis status and dose recommendations
                are correct. You can find this information in the Records section.
            </p>

            <ErrorDialog
                error={error}
                open={showError}
                setOpen={setShowError}
            />

        </BaseContainer>
    )
}

import { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DtapContext, DtapProvider, useDtapContext } from "../../../components/context/dtapState";
import { RecordType } from "./dtap_record_type";
import { RecordDetails } from "./dtap_record_details";
import { AskLater } from "./dtap_ask_later";
import { ConfirmUnvaccinated } from "./dtap_confirm_unvaccinated";
import { DontQualify } from "./dtap_dont_qualify";
import { checkAll } from "../../../utils/common";
import { SubroutineContext, SubroutineProvider } from "../../../components/context/subroutine";

type AddDtapStep = 'record_type' | 'record_details' | 'ask_later' | 'confirm_unvaccinated' | 'dont_qualify'

type AddDtapProps = RouteComponentProps<{
    uuid: string
    step: AddDtapStep
}>

const AddDtapStep: React.FC<{
    step: AddDtapStep
}> = ({step}) => {
    const { state } = useDtapContext(DtapContext)
    const { exitWizard } = useContext(SubroutineContext)
    const { product, last_vaccine_date } = state

    // Ensure our state contains the required values for a step
    switch (step) {
        case 'record_type':
            return <RecordType />
        case 'record_details':
            return <RecordDetails exitWizard={exitWizard}/>
        case 'ask_later':
            return <AskLater/>
        case 'confirm_unvaccinated':
            return <ConfirmUnvaccinated/>
        case 'dont_qualify':
            return <DontQualify/>
    }

    // If we're here, we have some data missing in state. Likely from a page reload or direct URL visit
    return <RecordType />
}

/**
 * Add patient-certified record workflow (wizard).
 */
export const AddDtap = ({ match }: AddDtapProps) => {

    return (
        <DtapProvider user={match.params.uuid}>
            <SubroutineProvider>
                <AddDtapStep step={match.params.step}/>
            </SubroutineProvider>
        </DtapProvider>
    )
}

import { Divider } from "../divider";
import { PageSubsection } from "../page_sections";
import Typography from "../typography";
import { JsonContent } from "../static_content";
import { InfoPanel } from "../info_panel";
import northJson from "../../content/flu_north.json";
import southJson from "../../content/flu_south.json";
import { VaccinationStatus } from "../vaccination_progress";
import { formatDate } from "../../utils/common";

type InfluenzaContentProps = {
    status: VaccinationStatus

    hemisphere: 'NORTH' | 'SOUTH'
}

export const influenzaStatusContent = ({ status }: Pick<InfluenzaContentProps, "status">) => {
    if (status.action_status === 'ELIGIBLE') {
        let dosesText;
        switch (status.doses_taken) {
            case 0:
                dosesText = 'no qualifying records'
                break
            case 1:
                dosesText = 'one qualifying record'
                break
        }
        return `You are eligible for vaccination with ${dosesText} held with Imunis`
    }

    if (status.action_status === 'UP_TO_DATE') {

        if (status.crossover_dose) {
            if (status.vaccine_status === 'full') {
                return "You are up-to-date and fully-vaccinated against influenza across both northern and southern hemispheres. Records show you took current vaccine products from both hemispheres which offer protection until the end of the current northern and southern hemisphere seasons. In future seasons, a single dose of the relevant hemisphere vaccine is recommended. You should have optimal protection 14 days after the last dose was taken."
            }
            if (status.vaccine_status === 'full_plus_delay') {
                return "You are up-to-date and fully-vaccinated against influenza across both northern and southern hemispheres. Records show you took current vaccine products from both hemispheres which offer protection until the end of the current northern and southern hemisphere seasons. In future seasons, a single dose of the relevant hemisphere vaccine is recommended. You should have achieved optimal protection having taken the last dose more than 14 days ago."
            }
        }

        if (status.vaccine_status === 'ineligible') {
            return 'You are ineligible for vaccination as your current age falls below the minimum recommended age for influenza vaccination.'
        }
        if (status.vaccine_status === 'partial') {
            return "You are up-to-date and partially-vaccinated for this hemisphere season, with a further dose due for optimal protection."
        }
        if (status.vaccine_status === 'full') {
            return "You are up to date and fully-vaccinated for this hemisphere season - you should have optimal protection 14 days after date of vaccination."
        }
        if (status.vaccine_status === 'full_plus_delay') {
            return "You are up to date and fully-vaccinated for this hemisphere season, and should have optimal protection at more than 14 days post-vaccination."
        }
        if (status.vaccine_status === 'unvaxed') {
            return "Vaccines for this influenza season are now available, but you recently took an influenza vaccine and must first complete the minimum interval of 4 weeks between doses. Once the 4 weeks has passed, your status will become ‘eligible for vaccination’ for this influenza season."
        }
    }

    if (status.action_status === 'MISSED') {
        return "Course incomplete for the season and the window for this season's vaccination has now closed."
    }

    if (status.action_status === 'UNAVAILABLE') {
        switch (status.unavailable_reason) {
            case 'CONFLICT':
                return "Vaccine records indicate a records conflict, therefore a vaccination status cannot be reliably calculated. Please contact the administering health provider to update records, and refer to schedule information for further guidance on dosing."
            case "UNSPECIFIED_PRODUCT":
                return "Vaccination status is currently unavailable due to a lack of necessary information about the vaccine products/type of products taken."
            case 'DOB_UNAVAILABLE':
                return 'Status is unavailable due to missing DOB'
            default:
                return "Imunis is unable to calculate your vaccination status as there is insufficient information about the product taken (hemisphere & therefore antigenic combination). See below for further information which may help determine when a further vaccine is due & contact your clinic if you have more specific information about the vaccine taken."
        }
    }

}

export const influenzaStatusWarnings = ({previous_dose}: InfluenzaContentProps['status']) => {
    return <>
        {previous_dose &&
        <PageSubsection>
            <InfoPanel severity="info" title="Two-dose requirement met">
                <Typography variant="bodyTextLarge">
                    Patients under 9 years of age taking the influenza vaccine for the first time are recommended to take 2 doses to generate optimum protection. As your records indicate previous doses (covering any flu season / hemisphere), a single dose schedule is now recommended.
                </Typography>
            </InfoPanel>
        </PageSubsection>}
    </>
}

export const nextDoseDue = ({ next_dose_due }: VaccinationStatus) => {

    if (next_dose_due){

        const dateRegex = new RegExp(/\d{4}-\d{2}-\d{2}/);
        const match = dateRegex.test(next_dose_due);

        if (match){
            const dateStr = formatDate(new Date(next_dose_due))
            return (
                <Typography variant="listTitle">Next dose due from: {dateStr}</Typography>
            )
        } else {
            return (
                <Typography variant="listTitle">Next dose due: {next_dose_due}</Typography>
            )
        }
    }
}


export const InfluenzaContent = ({ status, hemisphere }: InfluenzaContentProps) => {
    let content;
    if (hemisphere === 'NORTH') content = northJson;
    if (hemisphere === 'SOUTH') content = southJson;

    return (<>
        <PageSubsection>
            <Typography variant="bodyTextMediumDark">
                {influenzaStatusContent({ status: status })} [<a target="_blank" rel="noreferrer" href="https://imunis.com/vaccinaton_steps_key.pdf">key</a>]
            </Typography>
        </PageSubsection>

        <PageSubsection>
            {nextDoseDue({...status})}
        </PageSubsection>

        <Divider />

        {influenzaStatusWarnings(status)}

        {content && (
            <JsonContent>
                {content}
            </JsonContent>
        )}
    </>)
}

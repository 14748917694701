import { datadogRum } from '@datadog/browser-rum'
import { onLogOut } from '../../utils/auth'
import { setNotificationCount } from '../../utils/common'
import mixpanel from 'mixpanel-browser'

export const globalReducer = (state, action) => {
    switch (action.type) {
        case 'SET_NOTIFICATIONS':
            // update the notification_count in localStorage
            setNotificationCount(action.payload)
            return {
                ...state,
                notification_count: action.payload,
            }
        case 'SET_USER': {
            /*
            type UserInState = {
                uuid: string
                email: string
                full_name: string
                preferred_name: string
            }

            type UserInPayload = Partial<UserInState>

            This allows partial updates. Only relevant for the edit profile operation, where we only
            update/store changes to full name and display name.
            */
            const user = {
                uuid: action.payload.uuid ?? state.user.uuid,
                email: action.payload.email ?? state.user.email,
                full_name: action.payload.full_name ?? state.user.full_name,
                preferred_name: action.payload.preferred_name ?? state.user.preferred_name,
                date_of_birth: action.payload.date_of_birth ?? state.user.date_of_birth,
                feature_flags: action.payload.feature_flags ?? state.user.feature_flags,
                missing_dtap: action.payload.missing_dtap ?? state.user.missing_dtap,
            }

            // Set/update the copy of this data in localStorage. At the moment things are still
            // reading from there (see the deprecated fns in auth.ts)
            localStorage.setItem('uuid', user.uuid)
            localStorage.setItem('full_name', user.full_name);
            localStorage.setItem('preferred_name', user.preferred_name);
            localStorage.setItem('email', user.email)
            localStorage.setItem('feature_flags', JSON.stringify(user.feature_flags))
            localStorage.setItem('missing_dtap', user.missing_dtap ? "true" : "false")
            localStorage.setItem('date_of_birth', user.date_of_birth)

            if (!state.user?.uuid) {
                console.log("Setting user ID")
                datadogRum.setUser({
                    id: user.uuid,
                })

                mixpanel.identify(user.uuid)

                mixpanel.people.set({
                    "$name": "Customer",
                    "$email": user.uuid,
                });
            }

            return {
                ...state,
                user,
            }
        }
        case 'LOGOUT':
            // Keep the user data that's also stored in localStorage updated
            onLogOut()

            return { }
        default:
            console.warn(`Unknown dispatch type in GlobalContext: '${action.type}'`)
            return state;
    }
}

import { useState, useContext } from "react"
import { Link } from "@material-ui/core"
import { Button } from "../../components/button"
import styled from 'styled-components';
import { spacing } from '@material-ui/system';
import { BaseContainer } from '../../components/container'
import { StickyFooterGroup } from '../../components/sticky_footer_group';
import { ConnectionProfile } from '../../components/connection_profile';
import Typography from "../../components/typography";
import Logo from "../../assets/img/imunis-logo.svg";
import ActionDialog from "../../components/dialogs/action_dialog";
import ExclamationCircleIcon from "../../assets/icons/exclamation_circle";
import { ConnectionInviteContext, OnboardingJourney } from "../../components/context/connection_invite";
import Checkbox from "../../components/checkbox";
import ErrorDialog from "../../components/dialogs/error_dialog";
import { BackButton } from "../../components/buttons/back_btn";
import { ModalTitle, PageDescription } from "../../components/page_sections";
import { LegalDrawer } from "../../components/legal";

const Box = styled.div`${spacing}`;

const Consent = (props) => {
    const [consent, setConsent] = useState(false)
    const { state } = useContext(ConnectionInviteContext)
    const [confirmDecline, setConfirmDecline] = useState(false)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)

    const declineInvite = () => {
        props.decline()
            .catch(err => {
                console.error(err)
                let msg = 'Server error. Try again later'
                if (err.response?.data?.error) {
                    msg = err.response?.data?.error
                }
                setError(msg)
                setShowError(true)
            })
    }

    const organisationName = state.organisation
    const profileName = state.account_holder || state.full_name

    const journey = state.journey

    let firstParagraph, secondParagraph, errorMessage
    switch (journey) {
        case OnboardingJourney.CLINIC_ACCOUNT_HOLDER:
            firstParagraph = `By accepting this invitation and completing the secure matching process, you will be able to receive digital vaccination records from ${organisationName} for the above profile.`
            secondParagraph = `If you decline the invitation, you may still use the Imunis app, however you’ll need to request a further Connection invitation from ${organisationName} in order to receive digital records.`
            errorMessage = `This action cannot be undone. To receive digital records from ${organisationName} in the future, you will need to request a new invitation link.`
            break;
        case OnboardingJourney.CLINIC_DEPENDANT:
            firstParagraph = `By accepting this invitation and completing the secure matching process, you will be able to receive digital vaccination records from ${organisationName} for the above profile.`
            secondParagraph = `If you decline the invitation, you’ll need to request a further Dependant Connection invitation from ${organisationName} in order to receive digital records.`
            errorMessage = `This action cannot be undone. To receive digital records from ${organisationName} in the future, you will need to request a new invitation link.`
            break;
        case OnboardingJourney.SCHOOL_DEPENDANT:
            firstParagraph = `By accepting this invitation and completing the secure matching process, you will be able to share digital immune health records with ${organisationName} for the above profile.`
            secondParagraph = `If you decline the invitation, you may still use the Imunis app, however you’ll need to request a further Dependant Connection invitation from ${organisationName} in order to share digital records.`
            errorMessage = `This action cannot be undone. To share digital records with ${organisationName} in the future, you will need to request a new invitation link.`
            break;
    }

    return (
        <BaseContainer noHeader showCta extraComponent={<StickyFooterGroup>
            <Button primary fullWidth onClick={props.confirmConsent} disabled={!consent}>
                Accept
            </Button>
            <Button fullWidth onClick={() => { setConfirmDecline(true) }}>
                Decline
            </Button>
        </StickyFooterGroup>}>
            {journey === OnboardingJourney.CLINIC_ACCOUNT_HOLDER ?
                <img src={Logo} width="128px" alt="Imunis Logo" />
                :
                <BackButton />
            }

            <ModalTitle>
                {organisationName} has shared an invitation to connect and share records for
            </ModalTitle>

            <PageDescription>
                <Box marginTop={1}>
                    <ConnectionProfile name={profileName} year={state.year} />
                </Box>
            </PageDescription>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    {firstParagraph}
                </Typography>
            </PageDescription>

            <PageDescription>
                <Typography variant="bodyTextLarge">
                    {secondParagraph}
                </Typography>
            </PageDescription>

            <Box>
                <Checkbox
                    name="consent"
                    data-action="Privacy policy checkbox"
                    checked={consent}
                    onChange={() => setConsent(!consent)}
                    label={
                        <Typography variant="bodyTextSmall">
                            By ticking the box, I confirm that I have read and I agree to the
                            {' '}
                            <LegalDrawer document="privacy_policy">
                                <Link>Privacy Notice</Link>
                            </LegalDrawer>
                            {' '} and {' '}
                            <LegalDrawer document="service_agreement">
                                <Link>Account Service Agreement</Link>
                            </LegalDrawer>.
                        </Typography>}
                />
            </Box>

            <ActionDialog
                icon={<ExclamationCircleIcon fontSize="large" />}
                title={`Decline connection with ${organisationName}`}
                content={errorMessage}
                action_label="Confirm"
                action_callback={declineInvite}
                openDialog={confirmDecline}
                setOpenDialog={setConfirmDecline}
            />
            <ErrorDialog open={showError} setOpen={setShowError} error={error} />

        </BaseContainer>
    )
}

export default Consent

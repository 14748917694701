import { BaseContainer } from "../../components/container";
import { Button } from '../../components/button';
import Typography from '../../components/typography';

const TestResetIntro = (props) => {

    return (
        <BaseContainer {...props}>
            <Typography variant="pageHeader">
                Reset first login / into
            </Typography>

            <table>
                <tbody>
                    <Button onClick={() => {localStorage.setItem('first_visit', "true"); alert("Reset complete")}}>
                        Reset first login
                    </Button>
                </tbody>
            </table>
        </BaseContainer>
    )
}
export default TestResetIntro;

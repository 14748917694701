import { makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, TextButton } from "../../../components/button"
import { BackButton } from "../../../components/buttons/back_btn"
import { BaseContainer } from "../../../components/container"
import { DtapSteps } from "../../../components/context/dtapReducer"
import { DtapContext, useDtapContext } from "../../../components/context/dtapState"
import { DatePicker, toISOString } from "../../../components/date_picker"
import { HeaderTitle } from "../../../components/page_sections"
import Typography from "../../../components/typography"
import { StickyFooterGroup } from "../../../components/sticky_footer_group"
import { isLoggedIn, getUserFullName, getEmail, getUUID, getDateOfBirth, getFeatureFlags } from "../../../utils/auth";
import { Step as PaperRecordStep } from "../../records/paper/new"
import { CollapsedSection } from "../../../components/collapsed_section"
import SelectInput from "../../../components/select_input";
import { MenuItem } from "@material-ui/core";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { axiosAuthedInstance } from "../../../utils/axiosApi"
import ErrorDialog from "../../../components/dialogs/error_dialog"


const useStyles = makeStyles((theme) => ({
    textButton: {
        marginTop: theme.spacing(2),
    },
    text: {
        margin: theme.spacing(1, 0)
    },
    input: {
        "&": {
            "margin-top": "32px !important",
        }
    }
}))

type AddDtapResponse = {
    record_keys: string
}

export const RecordDetails: React.FC<{
    exitWizard: (cb: () => void) => void
}> = ({exitWizard}) => {
    const classes = useStyles()
    const history = useHistory()

    const { state, dispatch } = useDtapContext(DtapContext)

    const [product, setProduct] = useState('')
    const [lastDoseDate, setLastDoseDate] = useState<string | null>(null)
    const [ageGroup, setAgeGroup] = useState('')
    const [lastDoseDateError, setLastDoseDateError] = useState('')
    const [isFormValid, setIsFormValid] = useState(false)
    const [isDateEnabled, setIsDateEnabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)

    const date_of_birth = getDateOfBirth() as string
    const maxDate = new Date()
    const dateOfBirth = new Date(date_of_birth)
    const minDate = dateOfBirth.setFullYear(dateOfBirth.getFullYear() + 12)

    const ff = getFeatureFlags()

    useEffect(() => {
        if (product && ageGroup=='not_specified') {
            setIsFormValid(true)
        } else if (product && ageGroup=='specified' && lastDoseDate) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }

        if (ageGroup == 'specified') {
            setIsDateEnabled(true)
        } else {
            setIsDateEnabled(false)
        }
    }, [product, lastDoseDate, ageGroup]) // TODO: add radio

    function handleSubmit() {

        setLoading(true)

        const payload = {
            product: product,
            last_dose_date: lastDoseDate,
        }

        axiosAuthedInstance.post<AddDtapResponse>(`/user/${getUUID()}/add-dtap/`, payload)
            .then((response) => {
                exitWizard(() => {
                    localStorage.setItem('missing_dtap', "false")
                    history.push(`/records/${getUUID()}/health`, {
                        addedRecords: response.data.record_keys,
                    })
                })
            }).catch(error => {
                console.error(error)
                setError('Error adding records.');
                setShowError(true)
            }).finally(() => {
                setLoading(false)
            })
    }

    // Workaround to allow styling of MUI components
    function getStyles() {
        return `
            #country-label {
                color: #FF5300;
            }

            #last_dose_date-label {
                color: black;
            }

            #last_dose_date-label.Mui-disabled {
                color: rgba(0, 0, 0, 0.38);
            }
        `
    }

    return (
        <BaseContainer noHeader>
            <BackButton/>
            <HeaderTitle>
                Diphtheria, Tetanus, Pertussis & Polio self-certification
            </HeaderTitle>

            <style>
                {getStyles()}
            </style>

            <CollapsedSection title={<Typography variant="bodyTextLargeBold">Criteria for full vaccination in childhood</Typography>}>
              <Typography variant="bodyTextMediumDark">

                <p><b>Tetanus (T/t)</b>: 3 doses</p>
                <p><b>Diphtheria (D/d)</b>: 3 doses</p>
                <p><b>Pertussis (whooping cough) (aP/ap/wp/P)</b>: 3 doses under 10 years OR 1 dose over 10 years</p>
                <p><b>Polio (IPV/OPV)</b>: 3 doses (IPV); 4 doses if OPV was given at any stage</p>

                <p>The brackets () show the abbreviated terms often used on vaccination records to refer to each disease. These vaccinations are often given as combination vaccines, such as the following:</p>
                <p>DTaP/DTaP-IPV</p>
                <p>Tdap/Tdap-IPV</p>
                <p>TD/TD-IPV</p>
                <p>3 doses of DTaP-IPV would qualify as fully-vaccinated in childhood for all 4 diseases. Note that diphtheria and pertussis are usually given with tetanus.</p>
              </Typography>
            </CollapsedSection>

            <CollapsedSection title={<Typography variant="bodyTextLargeBold">What if i’m uncertain?</Typography>}>
              <Typography variant="bodyTextMediumDark">
                <p>Many countries have administered vaccines against these diseases since the 1950s, and many people without vaccine records will have been vaccinated.
                However, vaccination rates have fallen in recent years, and people do miss recommended vaccinations. If you think it’s possible you weren’t fully-vaccinated
                in childhood, it would be safer not to self-certify, and refer to your Imunis status.</p>
               </Typography>
            </CollapsedSection>

            <SelectInput
                value={product}
                name="country"
                label="I was fully-vaccinated as a child against the following:"
                fullWidth
                onChange={(event) => {
                    setProduct(event.target.value as string)
                }}
                className={classes.input}
                margin="none"
                error={false}
                helperText=""
                required
            >
                <MenuItem key="tetanus" value="tetanus"><Typography variant="bodyTextMediumBold">Tetanus only</Typography></MenuItem>
                <MenuItem key="tetanus,diphtheria" value="tetanus,diphtheria"><Typography variant="bodyTextMediumBold">Tetanus & diphtheria only</Typography></MenuItem>
                <MenuItem key="tetanus,diphtheria,pertussis" value="tetanus,diphtheria,pertussis"><Typography variant="bodyTextMediumBold">Tetanus, diphtheria & pertussis only</Typography></MenuItem>
                <MenuItem key="tetanus,diphtheria,pertussis,polio" value="tetanus,diphtheria,pertussis,polio"><Typography variant="bodyTextMediumBold">Tetanus, diphtheria, pertussis & polio</Typography></MenuItem>
                <MenuItem key="tetanus,polio" value="tetanus,polio"><Typography variant="bodyTextMediumBold">Tetanus & polio only</Typography></MenuItem>
                <MenuItem key="polio" value="polio"><Typography variant="bodyTextMediumBold">Polio only</Typography></MenuItem>
             </SelectInput>

            <p><br/></p>

            <Typography variant="bodyTextLargeBold" component="h2" className={classes.text}>
                Age at last childhood dose
            </Typography>
            <p>
               If you know the age at which you received your last childhood vaccine dose please record it here  - alternatively records will default to 12 years of age.
            </p>

            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(event) => setAgeGroup(event.target.value)}
              >
                <FormControlLabel value="not_specified" control={<Radio />} labelPlacement="start" label="I don’t know - default to 12 years" />
                <FormControlLabel value="specified" control={<Radio />} labelPlacement="start" label="I do know - enter date" />
              </RadioGroup>
            </FormControl>

            <DatePicker
                    maxDate={maxDate}
                    minDate={minDate}
                    initialFocusedDate={maxDate}
                    disableFuture
                    fullWidth
                    id="last_dose_date"
                    name="last_dose_date"
                    margin="normal"
                    openTo="year"
                    label="Date of last vaccination"
                    views={["year", "month", "date"]}
                    placeholder={'dd MMM yyyy'}
                    value={lastDoseDate}
                    helperText={lastDoseDateError ||
                        <span data-action="Last dose date">
                            Last childhood dose date must be age 12 or greater
                        </span>
                    }
                    maxDateMessage="Lase dose date must be below today's date"
                    onChange={(date) => setLastDoseDate(date ? toISOString(date) : null)}
                    required
                    disabled={!isDateEnabled}
                />
            <ErrorDialog
                error={error}
                open={showError}
                setOpen={setShowError}
            />

            <Button primary fullWidth onClick={handleSubmit} loading={loading} disabled={!isFormValid}>
                Submit
            </Button>
            <br/><br/>
            <Button fullWidth onClick={() => {history.push(`/add-dtap/${getUUID()}/dont_qualify`) }}>
                I don't think I qualify
            </Button>

        </BaseContainer>
    )
}
